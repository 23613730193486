<template>
  <div>
    <b-form @submit="onSubmit">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg">
            <b-form-group id="input-group-1" label="Név" label-for="input-1">
              <b-form-input
                id="input-1"
                v-model="name"
                required
                placeholder="Név"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-1" label="Email" label-for="input-2">
              <b-form-input
                id="input-2"
                v-model="email"
                type="email"
                required
                placeholder="Email"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-1"
              label="Telefonszám"
              label-for="input-3"
            >
              <b-form-input
                id="input-3"
                v-model="phone"
                required
                placeholder="+3620/1234567"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Melyik napon?" label-for="example-datepicker">
              <b-form-datepicker
                id="example-datepicker"
                v-model="date"
                class="mb-2"
                placeholder="2020.01.01."
              ></b-form-datepicker>
            </b-form-group>
          </div>

          <div class="col-lg">
            <b-form-group
              id="input-group-1"
              label="Honnan?"
              label-for="input-4"
            >
              <b-form-input
                id="input-4"
                v-model="whereFrom"
                required
                placeholder="Felpakolás helye"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-1" label="Hova?" label-for="input-5">
              <b-form-input
                id="input-5"
                v-model="whereTo"
                required
                placeholder="Lepakolás helye"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-1"
              label="Munkavégzés rövid leírása"
              label-for="input-6"
            >
              <b-form-textarea
                id="textarea"
                v-model="text"
                placeholder="Részletek, szállítandó paraméterek (súly, hossz, darab)"
                rows="5"
                max-rows="8"
              ></b-form-textarea>
            </b-form-group>

            <div class="submitbutton">
              <b-button
                squared
                v-if="!isSending"
                type="submit"
                variant="primary"
                id="button-blue"
              >
                Küldés
              </b-button>
              <div v-if="isSending && !isSent">
                <b-spinner label="Ajánlatkérés küldése..."></b-spinner>
                Ajánlatkérés küldése...
              </div>
              <div v-if="isSending && isSent">
                <div v-if="sentSuccess">Ajánlatkérés sikeresen elküldve.</div>
                <div v-if="!sentSuccess">
                  Hiba történt az ajánlatkérés küldése közben, kérem próbálja
                  meg később.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>


<script>
import sendEmail from "../email";

export default {
  name: "Form",
  data() {
    return {
      isSending: false,
      isSent: false,
      sentSuccess: false,
      email: "",
      name: "",
      phone: "",
      date: "",
      whereFrom: "",
      whereTo: "",
      text: "",
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.isSending = true;

      sendEmail({
        email: this.email,
        name: this.name,
        phone: this.phone,
        date: this.date,
        whereFrom: this.whereFrom,
        whereTo: this.whereTo,
        text: this.text,
      }).then((result) => {
        this.isSent = true;
        this.sentSuccess = result.data.success;
      });
    },
  },
};
</script>

<style scoped>
.submitbutton {
  text-align: right;
}

#button-blue {
  background-color: #1441ff;
  border: 0px;
}

#button-blue:hover {
  background-color: royalblue;
  border: 0px;
}
</style>
