import firebase from "firebase/app";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCQcNRh7PHAJ0qXoL7TncyD8ZLJW1ysKpg",
  authDomain: "darusfuvaros-83c07.firebaseapp.com",
  databaseURL: "https://darusfuvaros-83c07.firebaseio.com",
  projectId: "darusfuvaros-83c07",
  storageBucket: "darusfuvaros-83c07.appspot.com",
  messagingSenderId: "438108113931",
  appId: "1:438108113931:web:900f07a9eeb3a4b2b78b0e",
  measurementId: "G-9R4S2SZMX2"
};

firebase.initializeApp(firebaseConfig);

export default function sendMail(data) {
  console.log("Sending email", data);

  const sendEmail = firebase.functions().httpsCallable('sendEmail');

  return sendEmail(data);
}