<template>
  <div id="offer" class="container-fluid">
    <div class="row">
      <div class="col-sm px-5 py-5 offer-text">
        <h3>Ajánlatkérés</h3>
        <p>
          Adja meg az igényelt fuvar és rakomány pontos részleteit, mi pedig a
          lehető leghamarabb visszajelzünk Önnek egy árajánlattal!
        </p>
      </div>

      <div class="col-sm form my-5">
        <Form />
      </div>
    </div>
  </div>
</template>


<script>
import Form from "./Form.vue";

export default {
  name: "Offer",
  props: {
    msg: String,
  },
  components: {
    Form,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  font-weight: bolder;
}

.offer-text {
  background-color: #1441ff;
  color: white;
  text-align: left;
}

@media (min-width: 992px) {
  .form {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}
</style>