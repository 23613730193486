<template>
  <div id="home" class="hello">
    <div class="container-fluid">
      <div class="row mt-5 text-center">
        <div class="col col-sm-7 banner"></div>
        <div class="col col-sm-5" style="background-color: black">
          <div class="bannertext">
            Darus fuvarozás több, mint 25 év tapasztalattal
          </div>
          <p class="bannersubtext">
            Keressen bizalommal! <br />
            <b-button
              squared
              class="mt-5"
              variant="primary"
              id="button-blue"
              href="#services"
              >Tovább</b-button
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.banner {
  background-image: url("/images/df-home2.jpg");
  width: 100%;
  height: 60vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#button-blue {
  background-color: #1441ff;
  border: 0px;
}

#button-blue:hover {
  background-color: royalblue;
  border: 0px;
}

.bannertext {
  margin-top: 3rem;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: black;
  padding: 0rem;
  color: white;
  text-align: right;
  align-content: center;
}

.bannersubtext {
  background-color: black;
  padding-top: 2rem;
  color: white;
  text-align: right;
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .banner {
    height: 63vh;
  }

  .bannertext {
    margin-top: 6rem;
    font-size: 2rem;
    font-weight: 500;
  }

  button {
    padding: 1rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border-radius: 0px;
  }
}
/* Large devices (desktops, 992px and up)*/

@media (min-width: 992px) {
  .banner {
    height: 70vh;
  }

  .bannertext {
    margin-top: 9rem;
    margin-left: 5rem;
    font-size: 3rem;
    font-weight: 500;
  }
  p {
    font-size: 1.25rem;
  }
}
</style>
