<template>
  <div>
    <b-carousel
      id="carousel"
      style="text-shadow: 0px 0px 2px #000"
      fade
      indicators
      controls
      img-width="1000"
      img-height="550"
    >
      <b-carousel-slide
        v-for="(slide, index) in slides"
        :key="`slide-${index}`"
        :img-src="slide.image"
        :text="slide.text"
      ></b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: "Slides",
  data() {
    return {
      slides: [],
    };
  },
  mounted() {
    for (let i = 0; i < 13; i++) {
      this.slides.push({
        image: `/images/slides/slide-${i + 1}.jpg`,
        text: null,
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
