<template>
  <footer class="container-fluid" style="background-color: black">
    <div class="row px-5 pt-3">
      <div class="col-4" style="line-height: 1.8rem">
        <span>
          <b>Nagy András E.V.</b><br />
          <b>Adószám:</b> 50495896-2-33<br />
          <b>Cím:</b> 2330 Dunaharaszti, Nagy Lajos utca 5/a
        </span>
      </div>
      <div class="col-4 text-center">
        <b-img
          center
          src="/images/greylogo.svg"
          style="height: 20%"
          class="mb-2"
        ></b-img>
        <p>DARUSFUVAROS</p>

        <p>© 1994 - 2020</p>
      </div>

      <div class="col-4 text-right">
        <p>
          made with ♥︎<br />
          <a href="https://www.behance.net/evelinnagy">- e.n.</a>
        </p>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer {
  color: #c9c9c9;
  font-size: 0.6rem;
}

a {
  color: #c9c9c9;
  text-decoration: underline;
}

a:hover,
a:active {
  color: lightgray;
}

@media (min-width: 768px) {
  footer {
    font-size: 1rem;
  }
  p,
  span {
    font-size: 0.7rem;
  }
}
</style>
