<template>
  <div class="container mt-5 mb-5">
    <div class="row">
      <div class="col-6 col-md-12">
        <div class="bluebg">
          <img class="img-fluid" :src="image" />
        </div>
      </div>
      <div class="text col-6 col-md-12">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["text", "image"],
};
</script>

<style lang="css" scoped>
.bluebg {
  background-color: #1441ff;
}

.bluebg img {
  position: relative;
  left: 20px;
  top: 20px;
}

.text {
  align-items: left;
}

@media (min-width: 768px) {
  .text {
    margin-top: 45px;
    align-items: center;
  }
}
</style>