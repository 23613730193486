<template>
  <section id="services">
    <div class="container">
      <div class="row mb-2">
        <div class="col mt-5 text-center">
          <h3>Szolgáltatások</h3>
        </div>
      </div>
      <div class="row mb-5" style="white-space: pre-line">
        <div class="col-12 col-md-4">
          <ServicesItem
            image="/images/daruzas.jpg"
            text="– Anyagmozgatás önrakodós teherautóval 
            – Darus szállítás
            – Konténer szállítás daruzással
            – Gépemelés"
          />
        </div>
        <div class="col-12 col-md-4">
          <ServicesItem
            image="/images/fuv.jpg"
            text="– Belföldi és nemzetközi fuvarozás
            – Teherszállítás
            – Közúti fuvarozás
            – Építőipari szállítmányozás
            – Rakomány szállítás"
          />
        </div>
        <div class="col-12 col-md-4">
          <ServicesItem
            image="/images/darfuv.jpg"
            text="– Speciális szállítási és daruzási feladatok megszervezése, lebonyolítása
            "
          />
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row services">
        <div class="col-12 col-xl-6 mr-5">
          <Slides />
        </div>

        <div class="col-xl">
          <p class="services-text text-justify p-4 mt-5 mb-5">
            Az évek során építőipari munkáktól kezdve autómentésig számtalan
            feladatot sikeresen ellátunk: jelenleg hat önrakodós teherautóval és
            négy pótkocsival rendelkezünk, melyek mind különböző teherbírásúak.
            A darus fuvarozás legnagyobb előnye, hogy a rakomány fel- és
            lepakolásához nem szükséges külön darut a helyszínre rendelni.
            Szolgáltatásunk Magyarország egész területén igénybe vehető, és
            nemzetközi fuvarokat is vállalunk. Darusautóink EURO 5-6
            besorolással rendelkeznek, továbbá kamionnal jól megközelíthető
            telephellyel rendelkezünk, így általunk az áru továbbszállítható
            behajtási engedélyhez kötött övezetekbe is. Munkavégzésünket
            precizitás és pontosság jellemzi, hiszen számunkra a legfontosabb az
            elégedett ügyfél. Amennyiben bármilyen szállítási, vagy daruzási
            feladata van, megtalálta a megfelelő szolgáltatást!
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ServicesItem from "./ServicesItem.vue";
import Slides from "./Slides";

export default {
  components: {
    ServicesItem,
    Slides,
  },
};
</script>


<style scoped>
h3 {
  font-weight: bolder;
}

.services-text {
  border-right: 5px solid #1441ff;
  border-top: 5px solid #1441ff;
}

@media (min-width: 576px) {
  .services {
    margin: 5rem;
  }
}
</style>