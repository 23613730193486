<template>
  <div id="app">
    <NavBar />
    <Home />
    <Services />
    <Offer />
    <Contact />
    <Footer />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import Home from "./components/Home.vue";
import Services from "./components/Services.vue";
import Offer from "./components/Offer.vue";
import Contact from "./components/Contact.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    NavBar,
    Home,
    Services,
    Offer,
    Contact,
    Footer,
  },
};
</script>

<style lang="sass">
@import "node_modules/bootstrap/scss/bootstrap.scss"
@import "node_modules/bootstrap-vue/src/index.scss"

#app
  font-family: Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
</style>


