<template>
  <div>
    <b-navbar fixed="top" toggleable="sm" type="light" variant="light">
      <b-navbar-brand>DARUSFUVAROS</b-navbar-brand>
      <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>
      <b-collapse id="nav-text-collapse" is-nav>
        <b-navbar-nav style="width: 100%" align="right">
          <b-nav-item href="#home">Kezdőlap</b-nav-item>
          <b-nav-item href="#services">Szolgáltatások</b-nav-item>
          <b-nav-item href="#offer">Árajánlat</b-nav-item>
          <b-nav-item href="#contact">Kapcsolat</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
