<template>
  <div id="contact" class="container-fluid">
    <div class="row py-5">
      <div class="col px-5">
        <h3>Kapcsolat</h3>
        <p>
          Amennyiben kérdése van, állunk rendelkezésére a hét minden napján!
        </p>
        <div class="row">
          <div class="col-sm">
            <div class="contact-details pt-4">
              <p>
                <b> Telefonszám:</b> +36 30 944 8545<br />
                <b>Email:</b> info@darusfuvaros.hu<br />
                <b>Telephely cím:</b> 1238 Budapest, Meddőhányó utca 1.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7855.457233014698!2d19.09122459991934!3d47.424447032189654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741e8037be5c8b7%3A0x2ac224b6a705b4c2!2sDarusfuvaros!5e0!3m2!1sen!2shu!4v1604682989772!5m2!1sen!2shu"
          frameborder="0"
          style="border: 0"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-fluid {
  background-image: url("/images/contact-image.jpg");
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
}
h3 {
  font-weight: bolder;
}

iframe {
  height: 38vh;
  width: 53vh;
}
.map {
  align-items: right;
}

.contact-details {
  line-height: 2rem;
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 992px) {
  iframe {
    height: 55vh;
    width: 75vh;
  }
}
</style>


